import React from "react";
import './App.css';

function Footer(){
    return(
        <footer><h5></h5></footer>
    );
}


export default Footer;