import React from "react";

class ChildComponent extends React.Component{

    constructor(){
        super()
        this.state = {

            name: "Arjun",
            age: 20,
            flag: false
        }
    }

    render(){
        let wordDisplay

        if(this.state.flag === true){
        wordDisplay = "In";
        }
        else{
        wordDisplay = "Out";
        }
        return (
            <div>
                <br></br>
                <h1 class="h1head">Deploy ReactJS App with AWS S3 Static Hosting</h1>
                <h2 class="h2head">Let’s learn how to deploy a Serverless web application using ReactJS and AWS S3.</h2>
                <h3>Coming Soon....</h3>
            </div>
        );
    }
}

export default ChildComponent;