import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Header';
import MainContent from './MainContent';
import BootStrapModule from './BootStrapModule';
import Footer from './Footer';
import Menu from './Menu';
import ChildComponent from './ChildComponent';

function App() {
  return (
    <div className="App">
     <Header />
     <Menu />
     <BootStrapModule />
   
     <ChildComponent />
     <Footer />
    </div>
  );
}

export default App;
